import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { UserDetails } from "src/components/UserDetails";
import { AudienceTable } from "../../components/AudienceTable";
import { DocumentTabNav } from "../../components/DocumentTabNav";
import MenuBar from "../../components/MenuBar";
import { info } from "../../services/api/Trivia";
import { QuestionsTable } from "./components/QuestionsTable";
import { ResultsTable } from "./components/ResultsTable";
import { TriviaWithDetails } from "../../common/apiTypes";
import { TriviaDetails } from "./components/TriviaDetails";

interface TriviaPageContentState {
  trivia: TriviaWithDetails;

  currentTab: "trivia" | "questions" | "audience" | "results" | "author";
}

class TriviaPageContent extends React.Component<
  RouteComponentProps<{ triviaId: string }>,
  TriviaPageContentState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      trivia: undefined,

      currentTab: "trivia",
    };
  }

  componentDidMount() {
    this.load();
  }

  async load() {
    const triviaId = this.props.match.params.triviaId;
    const response = await info({ triviaId }).catch((e) => alert(e));
    this.setState({
      trivia: response,
    });
  }

  markSurveyDeleted = async () => {
    try {
      if (!this.state.trivia) {
        return;
      }
      // TODO: Not yet implemented in the backend
      alert("Functionality not yet implemented");
    } catch (e) {
      alert("Error: " + e);
    }
  };

  public tabClass(tabName: string): string {
    if (this.state.currentTab === tabName) {
      return "nav-link active";
    } else {
      return "nav-link";
    }
  }

  public render() {
    return (
      <div>
        <MenuBar />
        <div className="p-4">
          <h1>Trivia Info</h1>
          <DocumentTabNav
            initialTab="trivia"
            tabs={[
              {
                id: "trivia",
                label: "Trivia",
                onClick: () => this.setState({ currentTab: "trivia" }),
              },
              {
                id: "author",
                label: "Sender",
                onClick: () => this.setState({ currentTab: "author" }),
              },
              {
                id: "audience",
                label: "Audience",
                onClick: () => this.setState({ currentTab: "audience" }),
              },
              {
                id: "questions",
                label: "Questions",
                onClick: () => this.setState({ currentTab: "questions" }),
              },
              {
                id: "results",
                label: "Results",
                onClick: () => this.setState({ currentTab: "results" }),
              },
            ]}
          />
          <div className="mt-4">
            {this.state.trivia && this.state.currentTab === "trivia" && (
              <TriviaDetails trivia={this.state.trivia} />
            )}

            {this.state.trivia?.user && this.state.currentTab === "author" && (
              <UserDetails user={this.state.trivia?.user} />
            )}
            {this.state.trivia?.channel &&
              this.state.currentTab === "audience" && (
                <AudienceTable teams={[this.state.trivia.channel]} />
              )}
            {this.state.trivia?.questions &&
              this.state.currentTab === "questions" && (
                <QuestionsTable questions={this.state.trivia?.questions} />
              )}
            {this.state.trivia?.winnersProfile &&
              this.state.currentTab === "results" && (
                <ResultsTable
                  winnersProfile={this.state.trivia.winnersProfile}
                  numberOfQuestions={this.state.trivia.questions.length}
                />
              )}
          </div>
        </div>
      </div>
    );
  }
}

export const TriviaPage = withRouter(TriviaPageContent);
