import React, { useState } from "react";

import { WorkflowSchema } from "@pollyslack/hub/src/schemas/workflow";
import { Survey, Track } from "@pollyslack/hub/src/types";

import { PollsTable } from "../../../components/PollsTable";
import { SurveysTable } from "../../../components/SurveysTable";
import { TracksTable } from "../../../components/TracksTable";
import { WorkflowsTable } from "../../../components/WorkflowsTable";

import { DocumentTabNav } from "../../../components/DocumentTabNav";
import {
  PollWithDetails,
  QnAWithDetails,
  TriviaWithDetails,
} from "../../../common/apiTypes";
import { TriviaTable } from "../../../components/TriviasTable";
import { QandasTable } from "../../../components/QnAsTable";

type OrgActivityProps = {
  polls: PollWithDetails[];
  surveys: Survey[];
  tracks: Track[];
  workflows: WorkflowSchema[];
  trivia: TriviaWithDetails[];
  qandas: QnAWithDetails[];
};

export const OrgActivity: React.FunctionComponent<OrgActivityProps> = (
  props: OrgActivityProps,
) => {
  const { polls, surveys, tracks, workflows, trivia, qandas } = props;
  const [tab, setTab] = useState("polls");
  const tabs = [
    {
      id: "polls",
      label: "Polls",
      onClick: () => setTab("polls"),
    },
    {
      id: "surveys",
      label: "Surveys",
      onClick: () => setTab("surveys"),
    },
    {
      id: "trivias",
      label: "Trivias",
      onClick: () => setTab("trivias"),
    },
    {
      id: "qandas",
      label: "Q&As",
      onClick: () => setTab("qandas"),
    },
    {
      id: "tracks",
      label: "Tracks",
      onClick: () => setTab("tracks"),
    },
    {
      id: "workflows",
      label: "Workflows",
      onClick: () => setTab("workflows"),
    },
  ];

  return (
    <div>
      <DocumentTabNav initialTab={tab} tabs={tabs} />
      {tab === "polls" && <PollsTable polls={polls} />}
      {tab === "qandas" && <QandasTable qnadas={qandas} />}
      {tab === "trivias" && <TriviaTable trivia={trivia} />}
      {tab === "surveys" && <SurveysTable surveys={surveys} />}
      {tab === "tracks" && <TracksTable tracks={tracks} />}
      {tab === "workflows" && <WorkflowsTable workflows={workflows} />}
    </div>
  );
};
