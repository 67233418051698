import { QnAWithDetails } from "../../common/apiTypes";
import { post } from "./rest";

const pollsRoot = "/qandas/";

export async function info(params: { qnaId: string }): Promise<any> {
  return await post(`${pollsRoot}info`, params);
}

export async function list(searchParams: {
  search?: string;
  showDeleted?: boolean;
}): Promise<QnAWithDetails[]> {
  return await post(`${pollsRoot}list`, searchParams);
}

export async function markDeleted(qnaId: string): Promise<any> {
  return await post(`${pollsRoot}markDeleted`, { qnaId });
}
