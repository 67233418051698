import React from "react";

import { DocumentTable } from "../../../components/DocumentTable";
import { PreloadedQuestion } from "@pollyslack/hub/src/models/models/trivia/QuestionBank";

type QuestionsTableProps = {
  questions: PreloadedQuestion[];
};

type Question = PreloadedQuestion & {
  _id: string;
};

export const QuestionsTable: React.FunctionComponent<QuestionsTableProps> = (
  props: QuestionsTableProps,
) => {
  const properties = [
    { title: "Question ID", render: (d: PreloadedQuestion) => d.id },
    { title: "Title", render: (d: PreloadedQuestion) => d.title },
    ...["A", "B", "C", "D"].map((choice, index) => ({
      title: `Choice ${choice}`,
      render: (d: PreloadedQuestion) => d.choices[index]?.label || "",
    })),
    {
      title: "Correct",
      render: (d: PreloadedQuestion) =>
        d.choices.find((c) => c.correct)?.label || "",
    },
  ];

  const objects = props.questions.map((question) => ({
    ...question,
    _id: question.id,
  }));

  return <DocumentTable<Question> properties={properties} objects={objects} />;
};
