import { Organization } from "@pollyslack/hub/src/types";
import TimeSeriesAreaChart from "../../../components/Charts/TimeSeriesAreaChart";
import { Spinner } from "reactstrap";
import Select from "react-select";
import { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { getMAUData } from "../../../services/api/Organization";
import StaticAssets from "@pollyslack/assets/src/StaticAssets";
import moment from "moment";
import "./AnalyticsTab.css";

type AnalyticsTabProps = {
  organization: Organization;
  reload: () => Promise<void>;
};

export const AnalyticsTab: React.FunctionComponent<AnalyticsTabProps> = (
  props: AnalyticsTabProps,
) => {
  const [isLoading, setIsLoading] = useState(false);
  const [granularityLevel, setGranularityLevel] = useState<"ORG" | "EG">("ORG");
  const [monthsOfHistory, setMonthsOfHistory] = useState<number>(12);
  const [averageMAU, setAverageMAU] = useState<number>(null);
  const [peakMAU, setPeakMAU] = useState<number>(null);
  const [chartData, setChartData] =
    useState<{ date: Date; value: number }[]>(null);
  const granularityOptions = [
    { value: "ORG", label: `Usage data for this Workspace only` },
    { value: "EG", label: `Usage data for this Enterprise Grid` },
  ];
  const months = [3, 6, 9, 12];
  const monthOptions = months.map((m) => {
    return { value: m, label: `${m} Months` };
  });

  useEffect(() => {
    loadData(12, "ORG");
  }, []);

  const loadData = useCallback(
    async (monthsOfHistory: number, granularityLevel: "ORG" | "EG") => {
      setIsLoading(true);
      const res = await getMAUData(
        monthsOfHistory,
        granularityLevel === "ORG"
          ? props.organization.settings.slackTeamId
          : null,
        granularityLevel === "EG"
          ? props.organization.settings.slackEnterpriseId
          : null,
      );
      setChartData(res.chartData);
      setAverageMAU(res.average);
      setPeakMAU(res.peak);
      setIsLoading(false);
    },
    [monthsOfHistory, granularityLevel],
  );

  const onGranularityChanged = (
    monthsOfHistory: number,
    granularityLevel: "ORG" | "EG",
  ) => {
    setMonthsOfHistory(monthsOfHistory);
    setGranularityLevel(granularityLevel);
    loadData(monthsOfHistory, granularityLevel);
  };

  const onMonthsOfHistoryChange = (
    monthsOfHistory: number,
    granularityLevel: "ORG" | "EG",
  ) => {
    setMonthsOfHistory(monthsOfHistory);
    loadData(monthsOfHistory, granularityLevel);
  };

  const isUsageDataAvailable = useMemo(() => {
    return (
      averageMAU > 0 &&
      peakMAU > 0 &&
      chartData.length > 0 &&
      chartData.reduce((partialSum, a) => partialSum + a.value, 0) > 0
    );
  }, [averageMAU, peakMAU, chartData]);
  return (
    <div>
      {isLoading && (
        <div className="mt-5 text-center">
          <Spinner style={{ width: "5rem", height: "5rem" }} />
          <h5 className="pt-2">Loading....</h5>
        </div>
      )}
      {!isLoading && (
        <div className="mt-5">
          <div className="row ml-4">
            <div className="col col-md-2">
              <Select
                options={monthOptions}
                placeholder="Interval"
                value={_.find(monthOptions, (g) => g.value === monthsOfHistory)}
                onChange={(e) => {
                  onMonthsOfHistoryChange((e as any).value, granularityLevel);
                }}
              />
            </div>
            <div className="col col-md-4">
              {props.organization.settings.slackEnterpriseId && (
                <Select
                  options={granularityOptions}
                  placeholder="Granularity"
                  value={_.find(
                    granularityOptions,
                    (g) => g.value === granularityLevel,
                  )}
                  onChange={(e) => {
                    onGranularityChanged(monthsOfHistory, (e as any).value);
                  }}
                />
              )}
            </div>
            <div className="col col-md-2"></div>
            {(averageMAU > 0 || peakMAU > 0) && (
              <div className="col-md-4 pr-0">
                <div className="float-right pr-5">
                  {averageMAU && (
                    <StatBadge title="Avg MAU" value={averageMAU} />
                  )}

                  {peakMAU && (
                    <span className="pl-4">
                      <StatBadge title="Peak MAU" value={peakMAU} />
                    </span>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="row mt-5">
            <div className="col-12 pl-3 pr-5">
              <div className="row">
                <div className="col-12 pr-0 pl-0">
                  {isUsageDataAvailable && chartData && (
                    <TimeSeriesAreaChart
                      data={chartData}
                      tooltipKey="MAU: "
                      tsFrequency="month"
                      height={400}
                    />
                  )}
                </div>
              </div>
              {!isLoading && !isUsageDataAvailable && (
                <div className="col-12 text-center">
                  <div className="row mt-5">
                    <div className="col-md-12 mt-5">
                      <div>
                        <img src={StaticAssets.FREE_TRIAL} width="350" />
                      </div>
                      <div className="mt-3">
                        <strong>Usage data unavailable</strong>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="row ml-5 mt-3">
            <div className="col-12 px-5 mt-3">
              {!isLoading && chartData && isUsageDataAvailable && (
                <MAUTable data={chartData} />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const StatBadge: React.FC<{
  title: string;
  value: number;
}> = ({ title, value }) => {
  return (
    <span
      className="text-center ml-1 p-3 px-4"
      style={{
        border: "1px solid #cacaca",
        width: "100%",
        maxWidth: "10rem",
        borderRadius: "10px",
        lineHeight: "2.1rem",
      }}
    >
      <span style={{ fontSize: "1rem", fontWeight: 400 }}>{title}</span> &nbsp;
      &nbsp;
      <span style={{ fontSize: "1.1rem", fontWeight: "bold" }}>
        {Math.round(value).toLocaleString()}
      </span>
    </span>
  );
};

const MAUTable: React.FC<{
  data: { date: Date; value: number }[];
}> = ({ data }) => {
  // Sort data chronologically first
  const sortedData = [...data].sort((a, b) => {
    const dateA = a.date instanceof Date ? a.date : new Date(a.date);
    const dateB = b.date instanceof Date ? b.date : new Date(b.date);
    return dateA.getTime() - dateB.getTime();
  });

  const months = sortedData.map((d) => {
    try {
      if (d.date instanceof Date) {
        return moment.utc(d.date).format("MMM YYYY");
      }

      const momentDate = moment.utc(d.date, "MMM YYYY", true);
      if (momentDate.isValid()) {
        return momentDate.format("MMM YYYY");
      }
      return moment.utc(new Date(d.date)).format("MMM YYYY");
    } catch (e) {
      console.error("Error formatting date:", e);
      return String(d.date);
    }
  });
  const users = sortedData.map((d) => d.value);
  return (
    <table className="mau-table mx-auto">
      <thead>
        <tr>
          <th style={{ background: "#eee" }}>Month</th>
          {months.map((month, index) => (
            <th style={{ background: "#eee" }} key={index}>
              {month}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style={{ fontWeight: "bold" }}>Users</td>
          {users.map((user, index) => (
            <td key={index}>{user}</td>
          ))}
        </tr>
      </tbody>
    </table>
  );
};
