import React from "react";

import { list } from "../../services/api/QnA";

import { DocumentSearchPage } from "../../components/DocumentSearchPage";
import { QnAWithDetails } from "../../common/apiTypes";
import { QandasTable } from "../../components/QnAsTable";

export class QandAsPage extends React.Component {
  public render() {
    return (
      <DocumentSearchPage
        typeName="Q&A"
        placeholder="Q&A, Organization or User ID"
        load={list}
        renderTable={(qnadas: QnAWithDetails[]) => (
          <QandasTable qnadas={qnadas} />
        )}
      />
    );
  }
}
