import {
  SlackEnterpriseId,
  SlackTeamId,
  SlackUserId,
} from "../../slackIdTypes";

import { FeatureGrants } from "../feature";
import { Tier } from "../tier";

/** scope of subscription permissions */
export enum SubscriptionType {
  USER_LEVEL = "USER_LEVEL",
  ORG_LEVEL = "ORG_LEVEL",
  ENTERPRISE_LEVEL = "ENTERPRISE_LEVEL",
}

enum TrialSource {
  INSTALL = "INSTALL", // as part of the oauth install flow
  OPT_IN = "OPT_IN", // from the web app
  // For orgs from before paywall, we automatically gave them a 30-day business tier trial
  PREPAYWALL_AUTOMATIC = "PREPAYWALL_AUTOMATIC",
}

export interface TrialStartInfo {
  trialLengthInDays: number; // if starting a trial, how long it's for
  trialSource: TrialSource; // how they are getting the trial
  trialRequestReason?: string; // What they typed in when requested the trial, if anything
}

/**
 * Metadata used for ORG_LEVEL subs
 */
export interface OrgSubscriptionMetadata {
  startDate: Date;
  endDate: Date;
  requestedOn?: Date;
  requestedBy?: string; // Meteor userId
  tier: Tier | string; // may have discontinued plan
  source?: TrialSource;
  memo?: string;
  cancelReason?: string; // the reason the user decided to cancel their subscription
  cancelAdditionalInfo?: string; // when the user cancels a subscription they can provide addition feedback
  cancelDate?: Date; // the date the user cancelled their subscription
  cancelFavoriteFeature?: string; // when the user cancels a subscription they can provide the feature(s) they liked
  payment?: {
    stripeCustomerId: string;
    stripeSubscriptionItemId?: string;
  };
  billingAdminIds?: string[];
  subscribingUserId?: string;
}

/**
 * Metadata used for USER_LEVEL subs
 */
export interface UserSubscriptionMetadata {
  startDate: Date;
  endDate: Date;
  tier: Tier | string; // may have discontinued plan
  cancelReason?: string; // the reason the user decided to cancel their subscription
  cancelAdditionalInfo?: string; // when the user cancels a subscription they can provide addition feedback
  cancelDate?: Date; // the date the user cancelled their subscription
  cancelFavoriteFeature?: string; // when the user cancels a subscription they can provide the feature(s) they liked
  updatedAt?: Date; // the date the subscripiton object was updated
}

export interface licensesAssignedAtEntry {
  [authorId: string]: Date;
}

export interface SubscriptionSchema {
  _id: string; // mongo id
  createdAt: Date; // date sub was created
  startDate: Date; // date the sub starts at
  endDate?: Date; // date the sub ends at

  isTrial: boolean; // whether or not the sub is a trial
  isOneTime?: boolean; // indicates if the subscription was a one time purchase, if true, the endDate specifies the date the one-time purchase expires

  // active is implicitly true for embedded org subs
  active?: boolean; // whether or not the subscription is active

  payment?: {
    stripeCustomerId: string;
    stripeSubscriptionItemId?: string;
    stripeInvoiceId?: string;
  };

  subscriptionType: SubscriptionType;

  tier: Tier;
  featureOverrides?: FeatureGrants;

  // applicable for user level only
  subscribingUserId?: string; // mongo id of owner
  slackUserId?: SlackUserId; // slack id of owner
  authorIds?: string[]; // mongo ids of authors
  licensesAssignedAt?: licensesAssignedAtEntry; // date licenses was assigned
  authorSlackIds?: SlackUserId[]; // slack ids of authors
  billingAdminIds?: string[]; // mongo ids of admins
  billingAdminSlackUserIds?: SlackUserId[]; // slack ids of admins

  licenses?: number; // total number of licenses available with the subscription
  includedTrial?: boolean; // indicates that the subscription was started with CC trial

  // for org level only - will be added by migration; not present in embedded subs
  slackTeamId?: SlackTeamId;
  orgId?: string; // mongo id of org

  // for user or enterprise level
  slackEnterpriseId?: SlackEnterpriseId;

  // meta is from org.subscription.meta - not sure if useful to be in subscription DB
  meta?: {
    trials?: OrgSubscriptionMetadata[];
    prior?: UserSubscriptionMetadata[];
  };

  cancelReason?: string; // the reason the user decided to cancel their subscription
  cancelAdditionalInfo?: string; // when the user cancels a subscription they can provide addition feedback
  cancelDate?: Date; // the date the user cancelled their subscription
  cancelFavoriteFeature?: string; // when the user cancels a subscription they can provide the feature(s) they liked
  updatedAt?: Date; // the date the subscripiton object was updated
}

export enum SubscriptionAmplitudeEventStatus {
  UL_EVENT_SENT_FOR_TEAM_PLAN_PURCHASE = "UL_EVENT_SENT_FOR_TEAM_PLAN_PURCHASE",
  UL_EVENT_PENDING_FOR_TEAM_PLAN_PURCHASE = "UL_EVENT_PENDING_FOR_TEAM_PLAN_PURCHASE",
  UL_EVENT_SENT_FOR_TEAM_PLAN_CANCELLATION = "UL_EVENT_SENT_FOR_TEAM_PLAN_CANCELLATION",
  UL_EVENT_PENDING_FOR_TEAM_PLAN_CANCELLATION = "UL_EVENT_PENDING_FOR_TEAM_PLAN_CANCELLATION",
  UL_EVENT_SENT_FOR_TEAM_TO_BUSINESS_PLAN_UPGRADE = "UL_EVENT_SENT_FOR_TEAM_TO_BUSINESS_PLAN_UPGRADE",
  UL_EVENT_PENDING_FOR_TEAM_TO_BUSINESS_PLAN_UPGRADE = "UL_EVENT_PENDING_FOR_TEAM_TO_BUSINESS_PLAN_UPGRADE",
}

export interface OrgSubscriptionSchema {
  _id: string;
  createdAt: Date;
  startDate: Date;
  endDate?: Date;
  isTrial: boolean;
  payment?: {
    stripeCustomerId: string;
    stripeSubscriptionItemId?: string;
  };
  // featureOverrides applies to everyone on org subs, but only to authors in user subs
  featureOverrides?: FeatureGrants;
  licenses?: number;
  subscriptionType: SubscriptionType.ORG_LEVEL;
  tier: Tier;
  meta?: {
    trials?: OrgSubscriptionMetadata[];
    prior?: OrgSubscriptionMetadata[];
  };
  //mongo IDs of billing administrators
  billingAdminIds?: string[];
  subscribingUserId?: string;

  cancelReason?: string; // the reason the user decided to cancel their subscription
  cancelAdditionalInfo?: string; // when the user cancels a subscription they can provide addition feedback
  cancelFavoriteFeature?: string; // when the user cancels a subscription they can provide the feature(s) they liked
  amplitudeEventStatus?: SubscriptionAmplitudeEventStatus; // used for maintaining the amplitude event state
}

export interface UpgradeRequest {
  _id: string;
  orgId: string; // Foreign Key: Organization
  userId: string; // Foreign Key: User
  createdAt: Date;
  tier: Tier;
  text: string;
  adminIds: string[]; // Foreign Keys: User
  upgradeApproved: boolean;
}
