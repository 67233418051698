import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Team, User } from "@pollyslack/hub/src/types";
import { UserDetails } from "src/components/UserDetails";
import { AudienceTable, TeamWithId } from "../../components/AudienceTable";
import { DocumentTabNav } from "../../components/DocumentTabNav";
import MenuBar from "../../components/MenuBar";
import { info, markDeleted } from "../../services/api/QnA";
import { QnaDetails } from "./components/QnaDetails";
import { PollTools } from "./components/PollTools";
import { QnAWithDetails } from "../../common/apiTypes";
import { PlatformQandASummarySchema } from "@pollyslack/hub/src/platform";
import { ResponsesTable } from "./components/ResponsesTable";
import { QnaWebOnlyAudienceTable } from "./components/QnaWebOnlyAudienceTable";

interface PollPageContentState {
  qna?: QnAWithDetails;
  author?: User;
  audience: TeamWithId[];
  qnaSummary?: PlatformQandASummarySchema;
  currentTab: "qna" | "response" | "audience" | "author";
}

class QandAPageContent extends React.Component<
  RouteComponentProps<{ qnaId: string }>,
  PollPageContentState
> {
  private tabs = [
    {
      id: "qna",
      label: "Q&A",
      onClick: () => this.setState({ currentTab: "qna" }),
    },
    {
      id: "author",
      label: "Author",
      onClick: () => this.setState({ currentTab: "author" }),
    },
    {
      id: "audience",
      label: "Audience",
      onClick: () => this.setState({ currentTab: "audience" }),
    },
    {
      id: "response",
      label: "Responses",
      onClick: () => this.setState({ currentTab: "response" }),
    },
  ];

  constructor(props: any) {
    super(props);
    this.state = {
      qna: undefined,
      qnaSummary: undefined,
      author: undefined,
      audience: [],
      currentTab: "qna",
    };
  }

  componentDidMount() {
    this.load();
  }

  async load() {
    const qnaId = this.props.match.params.qnaId;
    const response = await info({
      qnaId,
    }).catch((e) => alert(e));
    this.setState({
      qna: response?.qna,
      qnaSummary: response?.qnaSummary,
    });
  }

  markPollDeleted = async () => {
    try {
      if (!this.state.qna) {
        return;
      }
      const response = await markDeleted(this.state.qna.id);
      if (!response.ok) {
        throw new Error(response.message);
      }
      alert("Polly deletion initiated");
      await this.load();
    } catch (e) {
      alert("Error: " + e);
    }
  };

  public render() {
    return (
      <div>
        <MenuBar />
        <div className="p-4">
          <h1>Q&A Info</h1>
          <DocumentTabNav initialTab="qna" tabs={this.tabs} />
          <div className="mt-4">
            <PollTools
              deleteDisabled={this.state.qna?.state === "deleted"}
              onDeleteClick={this.markPollDeleted}
            />
            {this.state.qna && this.state.currentTab === "qna" && (
              <QnaDetails
                qna={{
                  ...this.state.qna,
                  _id: this.state.qna?.id,
                }}
                qnaSummary={this.state.qnaSummary}
              />
            )}

            {this.state.currentTab === "author" && this.state.qna && (
              <UserDetails user={this.state?.qna?.userProfile} />
            )}
            {this.state?.qna &&
              this.state.currentTab === "audience" &&
              (this.state.qna?.slackChannel ? (
                <AudienceTable
                  teams={[this.state.qna?.slackChannel] as Team[]}
                />
              ) : (
                <QnaWebOnlyAudienceTable
                  data={[
                    {
                      _id: this.state?.qna.id,
                      teamId: this.state?.qna.userProfile.profile.org,
                      sendSlackId: this.state?.qna.userProfile.profile.user_id,
                      webOnly: true,
                    },
                  ]}
                />
              ))}
            {this.state.qnaSummary && this.state.currentTab === "response" && (
              <ResponsesTable qnaSummary={this.state.qnaSummary} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export const QandAPage = withRouter(QandAPageContent);
