import React from "react";

import { DocumentTable } from "../../../components/DocumentTable";
import {
  PlatformQandASummarySchema,
  PlatformResponseSchema,
} from "@pollyslack/hub/src/platform";
import { Date as DateComponent } from "../../../components/Date";

type ResponsesTableProps = {
  qnaSummary: PlatformQandASummarySchema;
};

export const ResponsesTable: React.FunctionComponent<ResponsesTableProps> = (
  props: ResponsesTableProps,
) => {
  const responses = [
    ...props.qnaSummary.pendingResponses,
    ...props.qnaSummary.approvedResponses,
    ...props.qnaSummary.doneResponses,
    ...props.qnaSummary.dismissedResponses,
  ];
  type UpdateResponseType = {
    _id: string;
    createdAt: string;
    title: string;
    state: string;
    replyCount: number;
    reactionCount: number;
  };

  const updatedResponses: UpdateResponseType[] = responses.map((response) => {
    return {
      _id: response.id,
      createdAt:
        (response.responses?.[0] as PlatformResponseSchema)?.createdAt || "",
      title: (response.responses?.[0] as PlatformResponseSchema)?.value || "",
      state: response.qna.state,
      replyCount: response.replies?.length || 0,
      reactionCount: response.reactions?.length || 0,
    };
  });

  const properties = [
    { title: "Response ID", render: (d: UpdateResponseType) => d._id },
    {
      title: "Created",
      render: (d: UpdateResponseType) => (
        <DateComponent date={new Date(d.createdAt)} />
      ),
    },
    { title: "Title", render: (d: UpdateResponseType) => d.title },
    { title: "State", render: (d: UpdateResponseType) => d.state },
    { title: "Replies", render: (d: UpdateResponseType) => d.replyCount },
    { title: "Reactions", render: (d: UpdateResponseType) => d.reactionCount },
  ];
  return (
    <DocumentTable<UpdateResponseType>
      properties={properties}
      objects={updatedResponses}
    />
  );
};
