import React from "react";

import { DocumentTable } from "../../../components/DocumentTable";
import { BoolCheck } from "../../../components/BoolCheck";

type Props = {
  _id: string;
  teamId: string;
  sendSlackId: string;
  webOnly: boolean;
};

type webOnlyTableProps = {
  data: Props[];
};

export const QnaWebOnlyAudienceTable: React.FunctionComponent<
  webOnlyTableProps
> = (props: webOnlyTableProps) => {
  const properties = [
    { title: "Team ID", render: (d: Props) => d.teamId },
    { title: "Sender ID", render: (d: Props) => d.sendSlackId },
    {
      title: "Web Only",
      render: (d: Props) => <BoolCheck check={d.webOnly} />,
    },
  ];
  return <DocumentTable<Props> properties={properties} objects={props.data} />;
};
