import React, { useState } from "react";

import { OrganizationLink } from "src/components/DocumentLink";
import { BoolCheck } from "../../../components/BoolCheck";
import { Code } from "../../../components/Code";
import { Date } from "../../../components/Date";
import { ExpanderContent, ExpanderToggle } from "../../../components/Expander";
import { TriviaWithDetails } from "../../../common/apiTypes";

type TriviaDetailsProps = {
  trivia: TriviaWithDetails;
};

export const TriviaDetails: React.FunctionComponent<TriviaDetailsProps> = (
  props: TriviaDetailsProps,
) => {
  const trivia = props.trivia;
  const [expanded, setExpanded] = useState(false);
  return (
    <div>
      <table className="table table-sm table-hover">
        <tbody>
          <tr>
            <th>ID</th>
            <td>{trivia._id}</td>
          </tr>
          <tr>
            <th>Created</th>
            <td>
              <Date date={trivia.createdAt} />
            </td>
          </tr>
          <tr>
            <th>Start Date</th>
            <td>
              <Date date={trivia.startDate} />
            </td>
          </tr>
          <tr>
            <th>Org</th>
            <td>
              <OrganizationLink docId={trivia.orgId} />
            </td>
          </tr>
          <tr>
            <th>Deleted</th>
            <td>
              <BoolCheck check={trivia.deleted} />
            </td>
          </tr>
          <tr>
            <th>Type</th>
            <td>{trivia.gameType}</td>
          </tr>
          <tr>
            <th>Win Type</th>
            <td>{trivia.winType}</td>
          </tr>
          <tr>
            <th>Participants</th>
            <td>{trivia.participantsCount}</td>
          </tr>
          <tr>
            <th>Response Time (Seconds)</th>
            <td>{trivia.questionsActiveFor}</td>
          </tr>
        </tbody>
      </table>
      View raw json{" "}
      <ExpanderToggle
        expanded={expanded}
        toggle={() => setExpanded(!expanded)}
      />
      <ExpanderContent expanded={expanded}>
        <Code object={props.trivia} />
      </ExpanderContent>
    </div>
  );
};
