import React, { useState } from "react";

import { SubscriptionSchema as Subscription } from "@pollyslack/hub/src/features/types";
import { User } from "@pollyslack/hub/src/types";

import { BoolCheck } from "../../../components/BoolCheck";
import { Code } from "../../../components/Code";
import { Date } from "../../../components/Date";
import { Overrides } from "../../../components/Overrides";
import { UserLink } from "../../../components/DocumentLink";
import { ExpanderContent, ExpanderToggle } from "../../../components/Expander";

type SubscriptionDetailsProps = {
  subscription: Subscription;
  owner?: User;
};

export const SubscriptionDetails: React.FunctionComponent<
  SubscriptionDetailsProps
> = (props: SubscriptionDetailsProps) => {
  const subscription = props.subscription;
  const [expanded, setExpanded] = useState(false);
  return (
    <div>
      <table className="table table-sm table-bordered table-hover">
        <tbody>
          <tr>
            <th>ID</th>
            <td>{subscription._id}</td>
          </tr>
          <tr>
            <th>Active</th>
            <td>
              <BoolCheck check={subscription.active} />
            </td>
          </tr>
          <tr>
            <th>Plan</th>
            <td>{subscription.tier}</td>
          </tr>
          <tr>
            <th>Owner</th>
            <td>
              <UserLink docId={subscription.subscribingUserId}>
                {" "}
                {props.owner ? props.owner.profile.email : null}
              </UserLink>
            </td>
          </tr>
          <tr>
            <th>Start Date</th>
            <td>
              <Date date={subscription.startDate} />
            </td>
          </tr>
          <tr>
            <th>End Date</th>
            <td>
              <Date date={subscription.endDate} />
            </td>
          </tr>
          <tr>
            <th>Licenses</th>
            <td>{subscription.licenses}</td>
          </tr>
          <tr>
            <th>Trial</th>
            <td>
              <BoolCheck check={subscription.isTrial} />
            </td>
          </tr>
          <tr>
            <th>One Time</th>
            <td>
              <BoolCheck check={subscription.isOneTime} />
            </td>
          </tr>
          <tr>
            <th>Feature Overrides</th>
            <td>
              <Overrides overrides={subscription.featureOverrides} />
            </td>
          </tr>
        </tbody>
      </table>
      View raw json{" "}
      <ExpanderToggle
        expanded={expanded}
        toggle={() => setExpanded(!expanded)}
      />
      <ExpanderContent expanded={expanded}>
        <Code object={props.subscription} />
      </ExpanderContent>
    </div>
  );
};
