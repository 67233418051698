import React from "react";
import * as _ from "lodash";

import {
  AnyFeature,
  isNumericFeature,
  SubscriptionSchema as Subscription,
  SubscriptionType,
} from "@pollyslack/hub/src/features/types";

import { BoolCheck } from "../BoolCheck";
import { Date } from "../Date";
import { SubscriptionLink, UserLink } from "../DocumentLink";
import { DocumentTable } from "../DocumentTable";
import { SubscriptionActionsToolbar } from "../SubscriptionActionsToolbar";
import { SubscriptionListResponseItem } from "../../../server/controllers/SubscriptionsController";

type SubscriptionsTableProps = {
  subscriptions: Subscription[];
  onTransferOwnershipClick?: (subscription: Subscription) => void;
  onUpdateSubscriptionClick?: (subscription: Subscription) => void;
  onDeactivateClick?: (subscription: Subscription) => void;
  onReactivateClick?: (subscription: Subscription) => void;
};

export const SubscriptionsTable: React.FunctionComponent<
  SubscriptionsTableProps
> = (props: SubscriptionsTableProps) => {
  const properties = [
    {
      title: "Subscription ID",
      render: (d: Subscription) =>
        d.subscriptionType === SubscriptionType.USER_LEVEL ? (
          <SubscriptionLink docId={d._id} />
        ) : (
          <>{d._id}</>
        ),
    },
    {
      title: "Active",
      render: (d: Subscription) => <BoolCheck check={d.active} />,
    },
    {
      title: "Trial",
      render: (d: Subscription) => <BoolCheck check={d.isTrial} />,
    },
    {
      title: "OneTime",
      render: (d: Subscription) => <BoolCheck check={d.isOneTime} />,
    },
    {
      title: "Licenses",
      render: (d: Subscription) =>
        d.subscriptionType === SubscriptionType.USER_LEVEL ? d.licenses : "N/A",
    },
    {
      title: "Type",
      render: (d: Subscription) => d.tier,
    },
    {
      title: "Start Date",
      render: (d: Subscription) => <Date date={d.startDate} />,
    },
    {
      title: "End Date",
      render: (d: Subscription) => <Date date={d.endDate} />,
    },
    {
      title: "Senders",
      render: (d: SubscriptionListResponseItem) => {
        if (d.subscriptionType === SubscriptionType.USER_LEVEL) {
          return (
            <>
              {Object.entries(d.senderEmailByIdMap).map(([id, email]) => {
                return (
                  <UserLink key={id} docId={id}>
                    {email}
                  </UserLink>
                );
              })}
            </>
          );
        }
        return "N/A";
      },
    },
    {
      title: "BillingAdmins",
      render: (d: SubscriptionListResponseItem) => {
        if (d.subscriptionType === SubscriptionType.USER_LEVEL) {
          return (
            <>
              {Object.entries(d.billingAdminEmailByIdMap).map(([id, email]) => {
                return (
                  <UserLink key={id} docId={id}>
                    {email}
                  </UserLink>
                );
              })}
            </>
          );
        }
        return "N/A";
      },
    },
    {
      title: "FeatureOverrides",
      render: (d: Subscription) => {
        if (d) {
          return (
            <>
              {_.keys(d.featureOverrides)
                .map((k) =>
                  isNumericFeature(k as AnyFeature)
                    ? `${k}: ${d.featureOverrides[k as AnyFeature]}`
                    : `${k}`,
                )
                .join(", ")}
            </>
          );
        }
        return "";
      },
    },
    {
      title: "",
      render: (d: Subscription) => (
        <SubscriptionActionsToolbar
          onTransferOwnershipClick={props.onTransferOwnershipClick?.bind(
            null,
            d,
          )}
          onUpdateSubscriptionClick={props.onUpdateSubscriptionClick?.bind(
            null,
            d,
          )}
          onDeactivateClick={
            d.active ? props.onDeactivateClick?.bind(null, d) : undefined
          }
          onReactivateClick={
            !d.active ? props.onReactivateClick?.bind(null, d) : undefined
          }
        />
      ),
    },
  ];

  return (
    <DocumentTable<Subscription>
      properties={properties}
      objects={props.subscriptions}
    />
  );
};
