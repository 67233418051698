import React from "react";

import { DocumentTable } from "../../../components/DocumentTable";
import { WinnersProfile } from "../../../common/apiTypes";

type ResultsTableProps = {
  winnersProfile: WinnersProfile[];
  numberOfQuestions: number;
};

export const ResultsTable: React.FunctionComponent<ResultsTableProps> = (
  props: ResultsTableProps,
) => {
  const properties = [
    {
      title: "User ID",
      render: (d: WinnersProfile) => d._id,
    },
    {
      title: "Name",
      render: (d: WinnersProfile) => d.name,
    },
    {
      title: "Email",
      render: (d: WinnersProfile) => d.email,
    },
    {
      title: "Slack ID",
      render: (d: WinnersProfile) => d.user_id,
    },
    {
      title: "Correct",
      render: (d: WinnersProfile) => `${d.correct}/${props.numberOfQuestions}`,
    },
  ];
  return (
    <DocumentTable<WinnersProfile>
      properties={properties}
      objects={props.winnersProfile}
    />
  );
};
