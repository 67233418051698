import * as React from "react";

import { Route, Switch } from "react-router-dom";

import { App } from "./pages/App";
import NotFound from "./pages/NotFound";
import { OrganizationPage } from "./pages/Organization";
import { OrganizationsPage } from "./pages/Organizations";
import { EnterprisePage } from "./pages/Enterprise";
import { PollPage } from "./pages/Poll";
import { PollsPage } from "./pages/Polls";
import ResumeToken from "./pages/ResumeToken";
import { SubScriptionPage } from "./pages/Subscription";
import { SurveyPage } from "./pages/Survey";
import { SurveysPage } from "./pages/Surveys";
import { TrackPage } from "./pages/Track";
import { TracksPage } from "./pages/Tracks";
import { UserPage } from "./pages/User";
import UserContext from "./pages/UserContext";
import { UsersPage } from "./pages/Users";
import { WorkflowPage } from "./pages/Workflow";
import { WorkflowsPage } from "./pages/Workflows";

import { SignedInContext } from "./SignedInContext";
import { QandAsPage } from "./pages/QandAs";
import { QandAPage } from "./pages/QandA";
import { TriviasPage } from "./pages/Trivias";
import { TriviaPage } from "./pages/Trivia";

export const PageRoutes = () => {
  const [signedIn, setSignedIn] = React.useState(false);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    if (signedIn) {
      return;
    }
    fetch("/auth/test", {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": "true",
      },
    }).then((response) => {
      if (response.status === 200) {
        setSignedIn(true);
      } else if (response.status === 401) {
        setSignedIn(false);
      } else {
        setError(new Error(`${response.status}: ${response.statusText}`));
      }
    });
  }, [signedIn]);

  if (error) {
    throw error;
  }

  return (
    <SignedInContext.Provider value={{ signedIn, setSignedIn }}>
      {signedIn ? (
        <Switch>
          <Route exact path="/" component={App} />
          <Route path="/usercontext" component={UserContext} />
          <Route path="/users" component={UsersPage} />
          <Route path="/user/:userId" component={UserPage} />
          <Route path="/polls" component={PollsPage} />
          <Route path="/poll/:pollId" component={PollPage} />
          <Route path="/qandas" component={QandAsPage} />
          <Route path="/qna/:qnaId" component={QandAPage} />
          <Route path="/surveys" component={SurveysPage} />
          <Route path="/survey/:surveyId" component={SurveyPage} />
          <Route path="/trivias" component={TriviasPage} />
          <Route path="/trivia/:triviaId" component={TriviaPage} />
          <Route path="/tracks" component={TracksPage} />
          <Route path="/track/:trackId" component={TrackPage} />
          <Route path="/workflows" component={WorkflowsPage} />
          <Route path="/workflow/:workflowId" component={WorkflowPage} />
          <Route
            path="/subscription/:subscriptionId"
            component={SubScriptionPage}
          />
          <Route path="/organizations" component={OrganizationsPage} />
          <Route path="/enterprise" component={EnterprisePage} />
          <Route path="/organization/:orgId" component={OrganizationPage} />
          <Route path="/meteor-resume-tokens" component={ResumeToken} />
          <Route path="*" component={NotFound} />
        </Switch>
      ) : (
        <App />
      )}
    </SignedInContext.Provider>
  );
};
