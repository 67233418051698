import React from "react";

import { list } from "../../services/api/Trivia";

import { DocumentSearchPage } from "../../components/DocumentSearchPage";
import { TriviaTable } from "../../components/TriviasTable";
import { TriviaWithDetails } from "../../common/apiTypes";

export class TriviasPage extends React.Component {
  public render() {
    return (
      <DocumentSearchPage
        typeName="Trivia"
        placeholder="Trivia, Organization or Track ID, or User Slack ID"
        load={list}
        renderTable={(trivias: TriviaWithDetails[]) => (
          <TriviaTable trivia={trivias} />
        )}
      />
    );
  }
}
