import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Link } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";

// enum values must match document routes
enum DocType {
  ORGANIZATION = "organization",
  POLL = "poll",
  SURVEY = "survey",
  CHANNEL = "channel",
  USER = "user",
  SUBSCRIPTION = "subscription",
  TRACK = "track",
  WORKFLOW = "workflow",
  QandA = "qna",
  Trivia = "trivia",
}

interface DocumentLinkProps {
  docId?: string;
  children?: React.ReactNode;
  scope?: string; // added to id to create unique element id
}

interface GenericDocumentLinkProps extends DocumentLinkProps {
  docType: DocType;
}

export const GenericDocumentLink: React.FunctionComponent<
  GenericDocumentLinkProps
> = (props: GenericDocumentLinkProps) => {
  const { docId, docType, children } = props;
  if (!docId) {
    return null;
  }
  // docId might start with a digit; make a legal html id from it, also remove special characters
  const target = `T${docId.replace(/[^\w\s]/gi, "")}`;

  const content = children ? (
    <span id={target}>{children}</span>
  ) : (
    <span id={target} className="text-monospace">
      {docId}
    </span>
  );

  return (
    <div className="d-flex flex-nowrap align-items-baseline">
      <button type="button" className="btn btn-sm">
        <Link to={`/${docType}/${docId}`}>
          <i className="fas fa-external-link-alt" />
        </Link>
      </button>
      <CopyToClipboard text={docId}>{content}</CopyToClipboard>
      <UncontrolledTooltip target={target}>
        {!!children && <p>{docId}</p>}
        click to copy to clipboard
      </UncontrolledTooltip>
    </div>
  );
};

export const OrganizationLink: React.FunctionComponent<DocumentLinkProps> = (
  props: DocumentLinkProps,
) => <GenericDocumentLink {...props} docType={DocType.ORGANIZATION} />;

export const PollLink: React.FunctionComponent<DocumentLinkProps> = (
  props: DocumentLinkProps,
) => <GenericDocumentLink {...props} docType={DocType.POLL} />;

export const QandALink: React.FunctionComponent<DocumentLinkProps> = (
  props: DocumentLinkProps,
) => <GenericDocumentLink {...props} docType={DocType.QandA} />;

export const TriviaLink: React.FunctionComponent<DocumentLinkProps> = (
  props: DocumentLinkProps,
) => <GenericDocumentLink {...props} docType={DocType.Trivia} />;

export const SurveyLink: React.FunctionComponent<DocumentLinkProps> = (
  props: DocumentLinkProps,
) => <GenericDocumentLink {...props} docType={DocType.SURVEY} />;

export const UserLink: React.FunctionComponent<DocumentLinkProps> = (
  props: DocumentLinkProps,
) => <GenericDocumentLink {...props} docType={DocType.USER} />;

export const ChannelLink: React.FunctionComponent<DocumentLinkProps> = (
  props: DocumentLinkProps,
) => <GenericDocumentLink {...props} docType={DocType.CHANNEL} />;

export const SubscriptionLink: React.FunctionComponent<DocumentLinkProps> = (
  props: DocumentLinkProps,
) => <GenericDocumentLink {...props} docType={DocType.SUBSCRIPTION} />;

export const TrackLink: React.FunctionComponent<DocumentLinkProps> = (
  props: DocumentLinkProps,
) => <GenericDocumentLink {...props} docType={DocType.TRACK} />;

export const WorkflowLink: React.FunctionComponent<DocumentLinkProps> = (
  props: DocumentLinkProps,
) => <GenericDocumentLink {...props} docType={DocType.WORKFLOW} />;
