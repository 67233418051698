import {
  BooleanFeature,
  featureUserFriendlyName,
  MergeFeatureGrants,
} from "../feature";
import { tierDefs, Tier } from "./tierDefinitions";

/** Returns a user friendly string that states which
 *  publically promoted self-serve tiers the provided feature
 *  is available on
 */
export function getFriendlyFeatureUpgradeMessage(
  feature: BooleanFeature,
): string {
  const availableTiers = whichTiers(feature);

  if (availableTiers.length === 0) {
    return "Feature available with upgrade";
  }
  let upgradeMessage =
    featureUserFriendlyName(feature) + " are available on the ";

  const numberOfTiers = availableTiers.length;

  for (let i = 0; i < numberOfTiers; i++) {
    upgradeMessage += availableTiers[i];

    // only add punctuation if we still have more tiers to add
    if (i < numberOfTiers - 1) {
      // if we're in a list of 2 just use 'and'
      if (numberOfTiers === 2 && i === 0) {
        upgradeMessage += " and ";
      } else if (numberOfTiers > 2) {
        //if we're at the last pair of names use an oxford comma
        if (i === numberOfTiers - 2) {
          upgradeMessage += ", and ";
        } else {
          upgradeMessage += ", ";
        }
      }
    }
  }

  if (numberOfTiers > 1) {
    upgradeMessage += " plans.";
  } else {
    upgradeMessage += " plan.";
  }
  return upgradeMessage;
}

/** Returns a list of the publically promoted self-serve  plans that the
 * provided feature is available on.
 *
 * Current self-serve plans include: StandardResponseBased and ProResponseBased
 */
export function whichTiers(feature: BooleanFeature): string[] {
  const free = MergeFeatureGrants(...tierDefs[Tier.FREE].features);
  const standard = MergeFeatureGrants(...tierDefs[Tier.FREE].features);
  const pro = MergeFeatureGrants(...tierDefs[Tier.FREE].features);
  const tiersWithAccess: string[] = [];

  // if free has the features, then it's inherited on standard, and pro
  if (free[feature]) {
    tiersWithAccess.push(tierDefs[Tier.BASIC].userName);
    tiersWithAccess.push(tierDefs[Tier.PRO].userName);
  } else if (
    // if standard tier has the feature, then it's inherited on pro
    standard[feature]
  ) {
    tiersWithAccess.push(tierDefs[Tier.BASIC].userName);
    tiersWithAccess.push(tierDefs[Tier.PRO].userName);
  } else if (pro[feature]) {
    tiersWithAccess.push(tierDefs[Tier.PRO].userName);
  }

  return tiersWithAccess;
}
