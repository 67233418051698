import React, { useState } from "react";
import DatePicker from "react-datetime";
import { Modal, ModalBody, ModalFooter } from "reactstrap";

import { SubscriptionType, Tier } from "@pollyslack/hub/src/features/types";
import "react-datetime/css/react-datetime.css";
import { PlanSelector } from "../Forms/PlanSelector";

type CreateSubscriptionModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (params: {
    plan: string;
    expiration?: Date;
    licenses: number;
    stripeCustomerId: string;
    memo: string;
  }) => void;
};
export const CreateSubscriptionModal: React.FunctionComponent<
  CreateSubscriptionModalProps
> = (props: CreateSubscriptionModalProps) => {
  const [plan, setPlan] = useState(Tier.PRO);
  const [expiration, setExpiration] = useState<Date>();
  const [licenses, setLicenses] = useState(1);
  const [stripeCustomerId, setStripeCustomerId] = useState("");
  const [memo, setMemo] = useState("");
  const [stripeIdValidationError, setStripeIdValidationError] = useState("");

  const validStripeIdPattern = new RegExp("^$|^cus_.*$");
  function handleChangeStripeId(value: string) {
    if (validStripeIdPattern.test(value)) {
      setStripeIdValidationError("");
    } else {
      setStripeIdValidationError("Id must start with 'cus_'");
    }
  }

  return (
    <Modal isOpen={props.isOpen}>
      <div className="modal-header">
        <h5 className="modal-title">Create a Subscription</h5>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={props.onClose}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <ModalBody>
        <div className="form-group">
          <label>Plan</label>
          <PlanSelector
            plan={plan as Tier}
            subscriptionType={SubscriptionType.USER_LEVEL}
            onChange={setPlan}
          />
        </div>
        <div className="form-group">
          <label>
            Expiration <i>(optional)</i>
          </label>
          <DatePicker onChange={(e) => setExpiration(new Date(e.toString()))} />
        </div>
        <div className="form-group">
          <label>
            Licenses <i>(optional)</i>
          </label>
          <input
            type="number"
            className="form-control"
            defaultValue={1}
            onChange={(e) => setLicenses(e.currentTarget.valueAsNumber)}
          />
        </div>
        <div className="form-group">
          <label>
            Stripe Customer ID <i>(optional)</i>
          </label>
          <input
            type="text"
            className={
              stripeIdValidationError.length > 0
                ? "form-control is-invalid"
                : "form-control"
            }
            onChange={(e) => {
              handleChangeStripeId(e.currentTarget.value);
              setStripeCustomerId(e.currentTarget.value);
            }}
          />
          <span className="form-text text-danger">
            {stripeIdValidationError}
          </span>
        </div>
        <div className="form-group">
          <label>
            Memo <i>(optional)</i>
          </label>
          <input
            type="text"
            className="form-control"
            onChange={(e) => setMemo(e.currentTarget.value)}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-light" onClick={props.onClose}>
          Close
        </button>
        <button
          disabled={stripeIdValidationError.length > 0}
          className="btn btn-primary"
          onClick={() =>
            props.onSubmit({
              plan,
              expiration,
              licenses,
              stripeCustomerId,
              memo,
            })
          }
        >
          Submit
        </button>
      </ModalFooter>
    </Modal>
  );
};
