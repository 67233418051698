import { post } from "./rest";
import { TriviaWithDetails } from "../../common/apiTypes";

const triviasRoot = "/trivias/";

export async function info(params: { triviaId: string }): Promise<any> {
  const res = await post(`${triviasRoot}info`, params);
  return res;
}

export async function list(searchParams: {
  search?: string;
  showDeleted?: boolean;
}): Promise<TriviaWithDetails[]> {
  const res = await post(`${triviasRoot}list`, searchParams);

  return res;
}

export async function markDeleted(triviaId: string): Promise<any> {
  return await post(`${triviasRoot}markDeleted`, { triviaId });
}
