import React, { useState } from "react";
import { QnAWithDetails } from "../../../common/apiTypes";

import { BoolCheck } from "../../../components/BoolCheck";
import { Code } from "../../../components/Code";
import { Date as CustomDate } from "../../../components/Date";
import { OrganizationLink } from "../../../components/DocumentLink";
import { ExpanderContent, ExpanderToggle } from "../../../components/Expander";
import { PlatformQandASummarySchema } from "@pollyslack/hub/src/platform";

type QandADetailsProps = {
  qna: QnAWithDetails;
  qnaSummary: PlatformQandASummarySchema;
};

export const QnaDetails: React.FunctionComponent<QandADetailsProps> = (
  props: QandADetailsProps,
) => {
  const qna = props.qna;
  const qnaSummary = props.qnaSummary;
  const [expanded, setExpanded] = useState(false);

  return (
    <div>
      <table className="table table-sm table-hover">
        <tbody>
          <tr>
            <th>ID</th>
            <td>{qna.id}</td>
          </tr>
          <tr>
            <th>Title</th>
            <td>{qna.title}</td>
          </tr>
          <tr>
            <th>Created</th>
            <td>
              <CustomDate date={new Date(qna.createdAt)} />
            </td>
          </tr>
          <tr>
            <th>Close Date</th>
            <td>
              <CustomDate date={new Date(qna.closeAt)} />
            </td>
          </tr>
          <tr>
            <th>Org</th>
            <td>
              <OrganizationLink docId={qna.userProfile.profile.org} />
            </td>
          </tr>
          <tr>
            <th>Web Only</th>
            <td>
              <BoolCheck check={qna?.slackChannel ? false : true} />
            </td>
          </tr>
          <tr>
            <th>Sent</th>
            <td>
              <BoolCheck check={qna.state !== "draft"} />
            </td>
          </tr>
          <tr>
            <th>Deleted</th>
            <td>
              <BoolCheck check={qna.state === "deleted"} />
            </td>
          </tr>
          <tr>
            <th>Response Count</th>
            <td>{qna.responseCount}</td>
          </tr>
          <tr>
            <th>Participants</th>
            <td>{qnaSummary.uniqueResponderCount}</td>
          </tr>
          <tr>
            <th>Anonymity Level</th>
            <td>{qna.anonymity}</td>
          </tr>
          <tr>
            <th>Allow Response Reactions</th>
            <td>
              <BoolCheck check={qna.allowResponseReactions} />
            </td>
          </tr>
          <tr>
            <th>Allow Multiple Responses</th>
            <td>
              <BoolCheck check={qna.allowMultipleResponses} />
            </td>
          </tr>
          <tr>
            <th>Moderation</th>
            <td>
              <BoolCheck check={qna.approveQuestions} />
            </td>
          </tr>
        </tbody>
      </table>
      View raw json{" "}
      <ExpanderToggle
        expanded={expanded}
        toggle={() => setExpanded(!expanded)}
      />
      <ExpanderContent expanded={expanded}>
        <Code object={props.qna} />
      </ExpanderContent>
    </div>
  );
};
